import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './App.css';
import React, { useState, useEffect } from 'react'
import Footer from './Footer'
import { Algego, Images } from './images/index.js';

function App() {

  const windowHeight = window.innerHeight / 2
  const [showArrow, setShowArrwo] = useState(false)

  const changeArrowVisibility = () => {
    if (window.scrollY > windowHeight) {
      setShowArrwo(true)
    } else {
      setShowArrwo(false)
    }
  }

  useEffect(() => {
    changeArrowVisibility()
    window.addEventListener("scroll", changeArrowVisibility)
  })

  return (
    <div className='App'>
      <div className='envelopAndArrow'>
        <div className={showArrow ? 'homeArrowShow' : 'homeArrowHide'}>
          <a href='#headerSection'><img src={Images.ArrowGray} alt='Arrow'></img></a>
        </div>
        <div className='envelopShow'>
          <a href='mailto:julieguillaume@algego.com'><img src={Images.EnvelopGray} alt='envelop'></img></a>
        </div>
      </div>
      <div id='headerSection'>
        <div className='algegoTop'>
          <a href='https://algego.com' target='_blank'>
            <img src={Images.AlgegoLogo} alt='ALGEGO'></img>
          </a>
        </div>
      </div>
      <div className='headerTxtSection'>
        <h1>ALGEGO x Desjardins</h1>
        <div className='firstLine'>10 ans de collaboration multimédia</div>
        <div className='secondLine'>Défiler vers le bas pour découvrir les mandats confiés à ALGEGO depuis 2014,<br />
          en communication interne et en formation</div>
      </div>
      <div className='zoneTimeLine'>
        <VerticalTimeline
          lineColor='#E6E7E9'
          className='timeLine'>

{/* 1- Project 2022-2023 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#766018', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #766018' }}
            date="2022 - 2023"
            dateClassName={'date date9'}
            iconStyle={{ background: '#E6E7E9' }}
            iconClassName={"algego"}
            icon={Algego}
            position='right'
          >
            <div className='date date1 date-B'>2022 - 2023</div>
            <div className='titreProjet'>
              <h1><strong>Plateforme de formation<br></br><em>Découverte</em></strong></h1>
              <h1>GPAP</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">
                Accompagnement stratégique et production de contenu multimédia pour une structure d’apprentissage en ligne (LMS) destinée aux conseillers en gestion de patrimoine afin de valoriser l’offre spécialisée auprès des 30 000 membres et 8 000 entreprises : étude de positionnement, stratégie éditoriale, création de contenu (conception des visuels
                et production de capsules vidéo), programmation des slides et des animations sur <em>Storyline</em>.

              </p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Maxime Dionne</p>
              <p className="vertical-timeline-element-subtitle">Directeur</p>
              <p className="vertical-timeline-element-subtitle">Direction Stratégies et Initiatives d’affaires</p>
              <p className="vertical-timeline-element-subtitle">en gestion de patrimoine</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <img src={Images.tuile2022} alt='projet'></img>
              </div>
            </div>
          </VerticalTimelineElement>

{/* 2- Project 2021-2022 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#842bc2', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #842bc2' }}
            date="2021 - 2022"
            dateClassName={'date date8'}
            iconStyle={{ background: '#E6E7E9' }}
            iconClassName={"algego"}
            icon={Algego}
            position='left'
          >
            <div className='date date1 date-B'>2021 - 2022</div>
            <div className='titreProjet'>
              <h1><strong>Vidéos en motion design<br></br><em>Règles du Comité de Bâle 3</em></strong></h1>
              <h1>Entreprises (CERFE)</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">
                Production d’une série de 4 vidéos en motion design pour modifier la perception des employés à l’égard du cadre d’agrément Bâle, et encourager les saines pratiques de gestion des risques.
              </p>
              <p className="vertical-timeline-element-subtitle paragraph2"><strong>Cliquez sur la vidéo ci-dessous.</strong></p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Jade Lapelletière</p>
              <p className="vertical-timeline-element-subtitle">Conseillère principale</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/705364954?h=a7783e76b6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
              </div>
            </div>
          </VerticalTimelineElement>

{/* 3- Project 2020-2022 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#274653', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #274653' }}
            date="2020 - 2022"
            dateClassName={'date date7'}
            iconStyle={{ background: '#E6E7E9' }}
            iconClassName={"algego"}
            icon={Algego}
            position='right'
          >
            <div className='date date1 date-B'>2020 - 2022</div>
            <div className='titreProjet'>
              <h1><strong>Websérie <em>Force E</em></strong></h1>
              <h1>Entreprises</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">Production d’une websérie de 6 épisodes et de 18 capsules de formation en studio virtuel 3D présentées par l’animateur Stéphane Bellavance.
              </p>
              <p className="vertical-timeline-element-subtitle paragraph2"><strong>Cliquez sur la vidéo ci-dessous.</strong></p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Benoit Trudel</p>
              <p className="vertical-timeline-element-subtitle">Conseiller principal</p>
              <p className="vertical-timeline-element-subtitle">Stratégies d’affaires, Pratiques de gestion,</p>
              <p className="vertical-timeline-element-subtitle">Développement des compétences gestionnaires</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/646103053?h=335b83afb7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
              </div>
            </div>
          </VerticalTimelineElement>

{/* 4- Project 2019-2020 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#b92d18', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #b92d18' }}
            date="2019 - 2020"
            dateClassName={'date date6'}
            iconStyle={{ background: '#E6E7E9' }}
            iconClassName={"algego"}
            icon={Algego}
            position='left'
          >
            <div className='date date1 date-B'>2019 - 2020</div>
            <div className='titreProjet'>
              <h1><strong>Plateforme pour iPad<br /><em>Projet Essor</em></strong></h1>
              <h1>DGIA</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">Plateforme pour iPad dans le cadre d’une stratégie de communication et d’accompagnement, présentée lors d’un <em>learning lunch</em> pour les employés de la DGIA (mise en situation avec deux comédiens).
              </p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Nicolas Richard</p>
              <p className="vertical-timeline-element-subtitle">Président et chef de l’exploitation</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <img src={Images.tuile2019} alt='projet'></img>
              </div>
            </div>
          </VerticalTimelineElement>
          
{/* 5- Project 2018-1 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5d316b', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #5d316b' }}
            // date="2018"
            // dateClassName={'date date5'}
            iconStyle={{ background: '#E6E7E9' }}
            iconClassName={"algego"}
            icon={Algego}
            position='right'
          >
            <div className='date date1 date-B'>2018</div>
            <div className='titreProjet'>
              <h1><strong>Vidéo interactive<br /><em>La confiance envers<br></br>les modèles et<br></br>stratégies de risque</em></strong></h1>
              <h1>Entreprises</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">Vidéo interactive présentée par Maria Ricciardi (Vice-présidente, Produits et opérations), pour diffuser les 3 capsules de formation animées par Marc Michaud (Chef d’équipe, Direction principale, Quantification des risques de crédit).
              </p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Jade Lapelletière</p>
              <p className="vertical-timeline-element-subtitle">Conseillère en Gestion du changement</p>
              <p className="vertical-timeline-element-subtitle">Gestion du changement et livraison RH</p>
              <p className="vertical-timeline-element-subtitle">Ressources humaines et Communications</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <img src={Images.tuile2018_2} alt='projet'></img>
              </div>
            </div>
            <div className='emptyDiv'></div>
          </VerticalTimelineElement>

{/* 6- Project 2018-2 */}

<VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5f170d', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #5f170d' }}
            date="2018"
            dateClassName={'date date4'}
            iconStyle={{ background: '#E6E7E9' }}
            iconClassName={"algego"}
            icon={Algego}
          >
            <div className='date date1 date-B'>2018</div>
            <div className='titreProjet'>
              <h1><strong>Captation vidéo<br /><em>Introduction<br></br>à la notation interne</em></strong></h1>
              <h1>Entreprises</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">Captation de la conférence donnée par Mohamed Tazi Mezalek (Directeur principal, Risques financiers et Capital Entreprises).
              </p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Jade Lapelletière</p>
              <p className="vertical-timeline-element-subtitle">Conseillère en Gestion du changement</p>
              <p className="vertical-timeline-element-subtitle">Gestion du changement et livraison RH</p>
              <p className="vertical-timeline-element-subtitle">Ressources humaines et Communications</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <img src={Images.tuile2018_1} alt='projet'></img>
              </div>
            </div>
          </VerticalTimelineElement>

{/* 7- Project 2017-2018 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5163e7', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #5163e7' }}
            date="2017 - 2018"
            dateClassName={'date date3'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
          >
            <div className='date date1 date-B'>2017 - 2018</div>
            <div className='titreProjet'>
              <h1><strong>Application web <em>Guide 4141</em></strong></h1>
              <h1>VPA</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">Conception et développement d’un guide multimédia pour les employés, sous forme d’application web présentant les nouveaux locaux à la <em>Tour de Montréal</em> : plans d’étage, visites virtuelles 360, liens vers sites d’information complémentaire, etc.
              </p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Mathieu Desrosiers</p>
              <p className="vertical-timeline-element-subtitle">Directeur principal</p>
              <p className="vertical-timeline-element-subtitle">Performance opérationnelle omnicanal et</p>
              <p className="vertical-timeline-element-subtitle">Services spécialisés</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <img src={Images.tuile2017} alt='projet'></img>
              </div>
            </div>
          </VerticalTimelineElement>

{/* 8- Project 2016-2018 */}

                    <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#21867a', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #21867a' }}
            date="2016 - 2018"
            dateClassName={'date date2'}
            iconStyle={{ background: '#E6E7E9', color: '#fff' }}
            iconClassName={"algego"}
            icon={Algego}
          >
            <div className='date date1 date-B'>2016 - 2018</div>
            <div className='titreProjet'>
              <h1><strong>Websérie interactive <em>DTM+</em></strong></h1>
              <h1>VPA</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">Websérie de 7 épisodes tournée en studio virtuel 3D pour accompagner la gestion de changement et le déménagement de 1500 employés dans la <em>Tour de Montréal</em>, au Stade Olympique : reportages, entrevues, sondages, jeux interactifs, etc. Également conception et développement d’une plateforme de diffusion multimédia sécurisée de style <em>Netflix</em>.
              </p>
              <p className="vertical-timeline-element-subtitle paragraph2"><strong>Cliquez sur la vidéo ci-dessous.</strong></p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Mathieu Desrosiers</p>
              <p className="vertical-timeline-element-subtitle">Directeur principal</p>
              <p className="vertical-timeline-element-subtitle">Performance opérationnelle omnicanal et</p>
              <p className="vertical-timeline-element-subtitle">Services spécialisés</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/296545782?h=6b31aedd9e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="BIXI_INTRO_v2_1920x1080_20mbps"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
              </div>
            </div>
          </VerticalTimelineElement>

{/* 9- Project 2014 */}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#e76f51', color: '#ffffff' }}
            contentArrowStyle={{ borderRight: '27px solid  #e76f51' }}
            date="2014"
            dateClassName={'date date1'}
            iconStyle={{ background: '#E6E7E9' }}
            iconClassName={"algego"}
            icon={Algego}
          >
            <div className='date date1 date-B'>2014</div>
            <div className='titreProjet'>
              <h1><strong>Vidéo de formation <em>Genesys</em></strong></h1>
              <h1>GPAP</h1>
            </div>
            <hr></hr>
            <div className='mandat'>
              <h3 className="vertical-timeline-element-title">Mandat</h3>
              <p className="vertical-timeline-element-subtitle">Vidéo de formation pour la plateforme interne <em>Genesys</em>.</p>
            </div>
            <div className='client'>
              <h3 className="vertical-timeline-element-title">Client</h3>
              <p className="vertical-timeline-element-subtitle">Sylvie Bastien</p>
              <p className="vertical-timeline-element-subtitle">Chef de projets</p>
              <p className="vertical-timeline-element-subtitle">Centres de contacts clients</p>
            </div>
            <div className='imageOfproject'>
              <div className='iframeWrapper'>
                <img src={Images.tuile2014} alt='projet'></img>
              </div>
            </div>
          </VerticalTimelineElement>

          {/* <VerticalTimelineElement
            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
            icon={""}
          /> */}
        </VerticalTimeline>
      </div>
      <Footer />
    </div>
  );
}

export default App;
